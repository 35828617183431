<template>


  <div class="contentwrapper">
      <div class="contentheadersub">
        <div>
          <b-button icon-left="plus" type="is-info" @click="editTree(null,0)">Create Tree</b-button>
          <b-button icon-left="redo" @click="getTrees(0)">Refresh</b-button>
        </div>
        <div class="taskSearchSel">
          <b-select v-model="ActiveGoalId" icon="bullseye-arrow" @input="setActiveGoal();getTrees(0);" expanded>
            <option v-for="(g, gi) in GoalList" :key="gi" :value="g._id">{{g.goaltitle}}</option>
          </b-select>
        </div>
      </div>
      <div class="isFlexGrow" style="position: relative;">
        <div class="contentsroll">
          <!-- content: start -->
          <div class="tsk lgreybg p11 m11" v-if="Trees.length === 0">No trees created</div>

          <div class="tsk tsk300 tskshadow m11 isFlex isFlexCol" v-for="(g, index) in Trees" :key="index" style="float: left; height: 150px;">
            <div class="isFlexGrow">
              <div class="txtbold txtlg txtc pb5">{{ g.treetitle }}</div>
              <div class="txtsm txtc pb5" v-if="g.goaltitle !== ''" :style="`color: ${g.goalcolor}`">{{ g.goaltitle }}</div>
              <div class="txtsm txtc pb5" v-else>Not associated with a goal</div>
              <div class="txtsm txtc pb11">{{ g.updatedAt | displaydate("ddd do MMM YY @ HH:mm") }}</div>
            </div>
            <div class="isFlex isFlexJustify">
              <div class="buttons">
                <b-button outlined icon-left="tree" size="is-small" @click="openMindMap(g)">Open</b-button>
                <!-- <b-button outlined icon-left="tree" size="is-small" @click="openTree(g)">Tree</b-button> -->
                <b-button icon-left="edit" outlined size="is-small" @click="editTree(g, index)">Edit</b-button>
                <b-button icon-left="trash" outlined size="is-small" @click="deleteTree(g, index)">Delete</b-button>
              </div>
            </div>
          </div>
          <!-- content: end -->
        </div>
      </div>
      <div class="contentfooter isFlex vlgreybg p7">
        <div class="isFlexGrow mr11">
          <b-pagination
            @change="getTrees($event)"
            :total="total"
            :current="current"
            :range-before="rangeBefore"
            :range-after="rangeAfter"
            :order="order"
            :size="size"
            :simple="isSimple"
            :rounded="isRounded"
            :per-page="perPage"
            :icon-prev="prevIcon"
            :icon-next="nextIcon"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
          >
          </b-pagination>
        </div>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { EventBus } from '../event-bus.js';
import { Utils } from '../mixins/utils.js';
import ModalTree from "@/modals/ModalTree.vue";
// import ModalTree from "@/modals/ModalTree.vue";
import ModalTreeAdd from "@/modals/ModalTreeAdd.vue";
import { Schemas } from "@/schemas/all.js";

export default {
  mixins: [Utils, Schemas],
  name: 'Trees',
  data() {
    return {
      Trees: [],
      isDemo: false,
      sub: localStorage.getItem("sub"),

      // pagination: start
      searchField: "",
      perPage: 20,
      prodCount: 0,
      total: 0,
      current: 1,
      rangeBefore: 3,
      rangeAfter: 1,
      order: "",
      size: "",
      isSimple: false,
      isRounded: false,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      // pagination: end
    }
  },
  created() {
    this.getGoalSelect();
    this.getTrees();
  },

  methods: {
    async getTrees(type) {
      this.isLoading = true;

      const sendData = {
        searchField: this.searchField,
        goalid: this.ActiveGoalId,
        perPage: this.perPage,
        skip: 0
      };
      this.current = type;
      if (type === 0) {
        this.current = 1;
        let getcount = await this.DataSend('post','/trees/listcount', sendData, 'rows', false);
        let totalRows = getcount;
        if (totalRows > this.perPage) {
          totalRows = totalRows / this.perPage;
          totalRows = Math.ceil(totalRows);
          this.total = totalRows * this.perPage;
        } else {
          this.total = totalRows;
        }
      }

      if (type > 1) {
        sendData.skip = this.perPage * (type - 1);
      }

      const getB = await this.DataSend('post', '/trees/list', sendData, 'rows', false);
      
      this.Trees = getB;
      this.isLoading = false;
    },

    async editTree(row, index) {
      let newtree = true;
      let useid = '';
      let usetitle = '';
      let usegoal = {
        _id: "",
        goaltitle: "Not associated with a goal",
        goalcolor: "#CCC"
      };
      if (row !== null) {
        newtree = false;
        useid = row._id;
        usetitle = row.treetitle;
        usegoal._id = row._id;
        usegoal.goaltitle = row.goaltitle;
        usegoal.goalcolor = row.goalcolor;
        if (row.sub !== this.sub) {
          this.isDemo = row.demo;
        }
      }
      this.modalT = this.$buefy.modal.open({
        parent: this,
        component: ModalTreeAdd,
        hasModalCard: true,
        props: {
          newtree,
          usetitle,
          useid,
          usegoal
        },
        events: {
          addNewRow: async value => {
            if(this.isDemo) {
              this.DataDemo();
              return;
            }
            if (newtree) {
              const nwt = {...this.treeSchema};
              nwt.treetitle = value.newtitle;
              nwt.data[0].name = value.newtitle;
              if (value.goal._id !== '') {
                nwt.goalid = value.goal._id;
                nwt.goalcolor = value.goal.goalcolor;
                nwt.goaltitle = value.goal.goaltitle;
              }
              const newrow = await this.DataSend('put', '/tree', nwt, 'rows', false);
              this.Trees.unshift(newrow);
            } else {
              row.treetitle = value.newtitle;
              row.goalid = value.goal._id;
              row.goalcolor = value.goal.goalcolor;
              row.goaltitle = value.goal.goaltitle;
              const uprow = await this.DataSend('put', '/tree', row, 'rows', false);
            }
          }
        }
      });
    },

    async deleteTree(row, index) {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>delete</b> this tree? This cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          this.Trees.splice(index, 1);
          this.DataDelete(`/tree/${row._id}`, '');
        }
      });
    },

    async openMindMap(row) {
      if (row.sub !== this.sub) {
          this.isDemo = row.demo;
      }
      const inh = document.body.clientHeight;
      const height = inh -100;
      const usedata = [...row.data];
      const finalData = JSON.parse(JSON.stringify(usedata));
      const finalData2 = [{
        name: 'new 1',
        children: [
          { name: 'new 2', children: [
            { name: 'new 3', children: [] },
            { name: 'new 4', children: [] },
          ]}
        ]
      }];
      this.modal = this.$buefy.modal.open({
        parent: this,
        component: ModalTree,
        hasModalCard: true,
        fullScreen: true,
        canCancel: false,
        props: {
          // mindmap: start
          value: finalData,
          // width: 700,
          height: height,
          draggable: true,
          xSpacing: 80,
          ySpacing: 20,
          gps: true,
          usegoal: false,
          usegoalid: '',
          usegoalname: row.treetitle,
          usegoalcolor: '',
          usebus: 'fromTree',
          data: {},
          // mindmap: end
        },
        events: {
          fromGoal: value => {},
          fromTree: async value => {
            if(this.isDemo) {
              this.DataDemo();
              return;
            }
            row.data = value;
            const uprow = await this.DataSend('put', '/tree', row, 'rows', false);
          },
        }
      });
    },

    // async openTree(row) {
    //   if (row.sub !== this.sub) {
    //       this.isDemo = row.demo;
    //   }
    //   const inh = document.body.clientHeight;
    //   const height = inh -100;
    //   const usedata = [...row.data];
    //   const finalData = JSON.parse(JSON.stringify(usedata));
    //   const finalData2 = [{
    //     name: 'new 1',
    //     children: [
    //       { name: 'new 2', children: [
    //         { name: 'new 3', children: [] },
    //         { name: 'new 4', children: [] },
    //       ]}
    //     ]
    //   }];
    //   console.log('finalData', finalData);
    //   this.modal = this.$buefy.modal.open({
    //     parent: this,
    //     component: ModalTree,
    //     hasModalCard: true,
    //     fullScreen: true,
    //     canCancel: false,
    //     props: {
    //       // mindmap: start
    //       indata: finalData,
    //       // width: 700,
    //       height: height,
    //       draggable: true,
    //       xSpacing: 80,
    //       ySpacing: 20,
    //       gps: true,
    //       usegoal: false,
    //       usegoalid: '',
    //       usegoalname: row.treetitle,
    //       usegoalcolor: '',
    //       usebus: 'fromTree',
    //       // mindmap: end
    //     },
    //     events: {
    //       fromGoal: value => {},
    //       fromTree: async value => {
    //         if(this.isDemo) {
    //           this.DataDemo();
    //           return;
    //         }
    //         row.data = value;
    //         const uprow = await this.DataSend('put', '/tree', row, 'rows');
    //       },
    //     }
    //   });
    // },
  }
}
</script>