<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title" v-if="isNew">Create a new tree</p>
      <p class="modal-card-title" v-else>Edit Tree</p>
    </header>
    <section class="modal-card-body">
      <div class="tab-item" v-if="loading">
        <b-progress type="is-info"></b-progress>
      </div>
      <div v-else>
        <b-field label="Tree name">
          <b-input v-model="newtitle"></b-input>
        </b-field>
        <b-field label="Assign to goal" class="mt7">
          <b-select placeholder="Select Goal" expanded v-model="goalSelected">
            <option v-for="gc in GoalList" :value="gc" :key="gc._id">{{ gc.goaltitle }}</option>
          </b-select>
        </b-field>
      </div>
    </section>
    <footer class="modal-card-foot isFlex" style="padding: 10px;" v-if="!loading"> 
      <div class="isFlexGrow">
        <b-button @click="$parent.close()">
          Cancel
        </b-button>
      </div>
      <div>
        <b-button @click="submitForm(true)" v-if="isNew">Create</b-button>
        <b-button @click="submitForm(true)" v-else>Update</b-button>
      </div>
    </footer>
  </div>
</template>

<script>
import { Utils } from "@/mixins/utils.js";
import { Schemas } from "@/schemas/all.js";
export default {
  name: "ModalTreeAdd",
  mixins: [Utils, Schemas],
  props: {
    newtree: Boolean,
    useid: String,
    usetitle: String,
    usegoal: Object
  },
  data() {
    return {
      DataIn: this.data,
      Data: {},
      loading: true,
      GoalList: [],

      isNew: this.newtree,
      inTitle: this.usetitle,
      inGoal: this.usegoal,
      newtitle: this.usetitle,
      goalSelected: {
        _id: "",
        goaltitle: "No Goal Selected",
        goalcolor: "#CCC"
      },
    };
  },
  created() {
    this.getGoals();
  },
  methods: {
    async getGoals() {
      this.GoalList = [];

      const glin = await this.DataGet("/goals/parents");
      const gl = glin;

      if(this.isNew) {
        this.GoalList.push(this.goalSelected);
      } else {
        this.goalSelected = this.inGoal;
        this.GoalList.push(this.goalSelected);
      }

      const childarray = [];
      for (const g of gl) {
        // set style
        if (g.goalcolor === "") {
          g.goalcolor = "#CCCCCC";
        }
        const nr = {
          _id: g._id,
          goaltitle: g.goaltitle,
          goalcolor: g.goalcolor
        }
        this.GoalList.push(nr);
      }
      if(!this.isNew) {
        this.GoalList.push({
        _id: "",
        goaltitle: "Not associated with a goal",
        goalcolor: "#CCC"
      });
      }
      this.loading = false;
    },
    async submitForm(close) {
      if (this.newtitle === '') {
        this.$buefy.toast.open(`Please give your tree a name`);
        return;
      }
      this.$emit("addNewRow", { newtitle: this.newtitle, goal: this.goalSelected });
      if (close) {
        this.$parent.close();
      }
    },
  }
};
</script>
